import siteInfo from "@/constants/siteInfo";
import Head from "next/head";

const WebSiteSchema = () => {
  return (
    <Head>
      <script
        id="WebsiteSchema"
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: siteInfo?.siteName,
            url: process.env.NEXT_PUBLIC_WEBSITE_URL,
            potentialAction: {
              "@type": "SearchAction",
              target: process.env.NEXT_PUBLIC_WEBSITE_URL+'/search/{search_term_string}',
              "query-input": "required name=search_term_string"

            },
          }),
        }}
      />
    </Head>
  );
};

export default WebSiteSchema;
